import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Tooltip, message } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import AssociarPartilharProposta from '../../../components/PerfilProposta/Associar_e_PartilharProposta';
import TabelaDadosCarroProposta from '../../../components/CalculoProposta/TabelaDadosDoCarro';
import InfoPerfilClienteHubspotProposta from '../../../components/PerfilProposta/HubspotProposta/InfoPerfilClienteHubspotProposta';
import FormCamposCalculoProposta from '../../../components/PerfilProposta/PT/FormCamposCalculoProposta_ISV_IUC';
import PrecoFinalProposta from '../../../components/CalculoProposta/PrecoFinalProposta';
import Loading from '../../../components/Elements/Loading';
import FasesDaProposta from '../../../components/PerfilProposta/PerfilProposta/FasesDaProposta';
import EstimativaDeComissao from '../../../components/CalculoProposta/EstimativaDeComissao';
import DuracaoDeProcesso from '../../../components/CalculoProposta/DuracaoDeProcesso';
import DownloadDoPrintAnuncio from '../../../components/PerfilProposta/PerfilProposta/DownloadDeArquivos/DownloadDoPrintAnuncio';
import DownloadDasImagens from '../../../components/PerfilProposta/PerfilProposta/DownloadDeArquivos/DownloadDasImagens';
import { RiRadioButtonLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SimuladorDeCreditoDentroDaProposta from '../../../components/CalculoProposta/PT/SimuladorCreditoDentroDaProposta/SimuladorDeCreditoDentroDaProposta';

export default function PerfilDaPropostaPT({ dataUser }) {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [dataCarros, setDataCarros] = useState([]);

    const [isSuccessPrintAnuncio, setIsSuccessPrintAnuncio] = useState(false); // Controle de sucesso


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };


    const [propostaInfo, setPropostaInfo] = useState({
        linkFora: '',
        linkDaProposta: '',
        estadoDaProposta: '',
        idProposta: '',
        standData: [],
        tipoNegocio: '',
        tipoProposta: '',
        outros: [],
        propostaExpirou: null,
        propostaDisponivelOnline: null,
        imagensParaDownload: [],
        printAnuncioParaDownload: []
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        hibridoPlugIn: 0,
        wltp: 0,
        particulas: 0,
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        outrosServicos: '',
        valorTotalISV: 0,
        valorTotalIUC: 0,
        hibridoMais25kmAutonomia: 0
        // valorTotalISV2025: null
    });

    //Valores Totais
    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        validaSeValorNaoTemIva: 0,
        taxaDeServico: 3000,
        taxaDeFinanciamento: 400,
        taxaDeFinanciamentoAtivo: 0,
        caucaoFinanciamento: 0,
        valorExpress: 849,
        valorExpressAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        // valorTotalISV2025: null,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        caucaoIvaPaisOrigem: 0,
        compsIUC: {
            iucCilindrada: 0,
            iucCO2: 0,
            iucAdicionalCO2: 0,
            iucCoeficienteAnoMatriculo: 0,
            iucAdicionalGasoleo: 0
        },
        compsISV: {
            isvcomponentecilindrada: 0,
            isvcomponenteambiental: 0,
            isvReducaoAnosUsoCilindrada: 0,
            isvReducaoAnosUsoAmbiental: 0,
            isvAgravamentoParticulas: 0,
            isvReducaoAnosUsoParticulas: 0,
            isvTaxaAplicavelTabela: '',
        }
    });

    const [hubspotInfo, setHubspotInfo] = useState({
        idDoDeal: '',
        nomeDoClienteDeal: '',
        emailDoClienteDeal: '',
        etapaDoDeal: '',
        pipelineDoDeal: '',
    });


    // Parsea o nome da proposta da URL usando URLSearchParams
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search); // Usa URLSearchParams.
        const nomeProposta = searchParams.get('n'); // Obtém o nome da proposta.
        const isNew = searchParams.get('new') === 'true'; // Obtém o parametro só para mostrar a notificação de proposta criada.

        if (isNew) {
            message.success('Proposta criado com sucesso!')
        }

        if (nomeProposta) {
            // Definir a URL de requisição com base no nomeProposta e dados do usuário
            const requestUrl = `${process.env.REACT_APP_GET_PROPOSTA_PT}?n=${nomeProposta}&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}`;

            // Inicia o carregamento
            setLoading(true);

            // Faz a requisição à API usando Axios
            axios.get(requestUrl)
                .then((response) => {
                    console.log(response)
                    if (response.data.sucesso) {

                        const allDataCarro = response.data.data.carro;

                        console.log(allDataCarro)

                        const allHubspot = response.data.data.proposta.hubspot;
                        const allValores = response.data.data.simulacao;
                        const allValoresExtra = response.data.data.simulacao_extras;
                        const allProposta = response.data.data.proposta;
                        const allStand = response.data.data.stand;

                        console.log(allValores)

                        setDataCarros(allDataCarro);// Atualiza os dados dos carros com a resposta

                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            linkFora: allProposta.link_proposta,
                            linkDaProposta: allProposta.link_proposta_view,
                            estadoDaProposta: allProposta.estado_proposta_hubspot,
                            tipoNegocio: allProposta?.tipo_contrato || "",
                            tipoProposta: allProposta?.tipo_proposta,
                            idProposta: allProposta.id_proposta,
                            standData: allStand,
                            outros: allProposta,
                            propostaExpirou: allProposta.expirou,
                            propostaDisponivelOnline: allProposta.indisponivel_online,
                            imagensParaDownload: allProposta.imagens_locais_proposta,
                            printAnuncioParaDownload: allProposta.print_proposta[0],
                        }));

                        setIsvIucData((prevData) => ({
                            ...prevData,
                            cilindrada: allValores.cilindrada,
                            novoUsado: allValores.novo,
                            tipoCombustivel: allValores.tipo_de_combustivel,
                            emissoesCO2: allValores.emissoes_numerico,
                            hibridoPlugIn: allValores.hibrido,
                            wltp: allValores.wltp,
                            dataPrimeiroRegisto: allValores.data_registo,
                            valorTotalISV: allValores.isv,
                            valorTotalIUC: allValores.iuc,
                            hibridoMais25kmAutonomia: allValores.hibrido_mais_25km_autonomia,
                            // valorTotalISV2025: allValores.isv_2025,
                            particulas: allValores.particulas,
                        }));


                        setValoresTotais((prevData) => ({
                            ...prevData,
                            precoViaturaComIva: allDataCarro.preco_final_carro,
                            precoViaturaSemIva: allDataCarro.preco_final_carro_sem_iva,
                            validaSeValorNaoTemIva: allDataCarro.tem_iva,
                            taxaDeServico: allValores.taxadeservico,
                            taxaDeFinanciamento: allValores.comissao_credito,
                            taxaDeFinanciamentoAtivo: allValores.check_comissao_credito,
                            caucaoFinanciamento: allValores.caucao_comissao_credito,
                            valorExpress: allValores.comissao_express,
                            valorExpressAtivo: allValores.check_comissao_express,
                            comissao: allValores.comissaodeservico,
                            valorTotalISV: allValores.isv,
                            valorTotalIUC: allValores.iuc,
                            // valorTotalISV2025: allValores.isv_2025,
                            valorPrecoChaveNaMaoParticular: allValores.valor_chavenamao_particular,
                            valorPrecoChaveNaMaoEmpresa: allValores.valor_chavenamao_empresa,
                            caucaoIvaPaisOrigem: allValores.caucao_iva_pais_origem,
                            compsIUC: {
                                iucCilindrada: allValoresExtra.iuc_cilindrada,
                                iucCO2: allValoresExtra.iuc_co2,
                                iucAdicionalCO2: allValoresExtra.iuc_adicional_co2,
                                iucCoeficienteAnoMatriculo: allValoresExtra.iuc_coeficiente_ano_matriculo,
                                iucAdicionalGasoleo: allValoresExtra.iuc_adicional_gasoleo,
                            },
                            compsISV: {
                                isvcomponentecilindrada: allValoresExtra.isv_componente_cilindrada,
                                isvcomponenteambiental: allValoresExtra.isv_componente_ambiental,
                                isvReducaoAnosUsoCilindrada: allValoresExtra.isv_reducao_anos_uso_cilindrada,
                                isvReducaoAnosUsoAmbiental: allValoresExtra.isv_reducao_anos_uso_ambiental,
                                isvAgravamentoParticulas: allValoresExtra.isv_agravamento_particulas,
                                isvReducaoAnosUsoParticulas: allValoresExtra.isv_reducao_anos_uso_particulas,
                                isvTaxaAplicavelTabela: allValoresExtra.isv_taxa_aplicavel_tabela,
                            }
                        }));

                        if (allHubspot?.deal) {
                            setHubspotInfo((prevData) => ({
                                ...prevData,
                                idDoDeal: allHubspot.deal.id_deal_hubspot,
                                nomeDoClienteDeal: allHubspot.contacto.firstname,
                                emailDoClienteDeal: allHubspot.contacto.email,
                                etapaDoDeal: allHubspot.deal.dealstage_name,
                                pipelineDoDeal: allHubspot.deal.pipeline_name,
                            }));
                        }

                        setLoading(false);

                    } else {
                        message.error(response.data.mensagem);
                        setLoading(true);

                        setTimeout(() => {
                            navigate(`/${t('menu.ver_propostas')
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')  // Remove accents
                                .replace(/ /g, '-')}`);
                        }, 2000);

                    }

                })
                .catch((error) => {
                    console.error('Erro ao buscar dados da proposta:', error); // Trata erros
                })
            // .finally(() => {
            //     setLoading(false); // Para o loading depois da requisição
            // });
        }
    }, [dataUser.iDTokenAuthenticated]); // Removi nomeProposta das dependências, pois é tratado internamente




    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const isNew = searchParams.get('new') === 'true';

        console.log('propostaInfo', propostaInfo)

        if (isNew && !isSuccessPrintAnuncio && propostaInfo.idProposta && !propostaInfo.printAnuncioParaDownload) {

            console.log('entrou')
            
            const fetchData = async () => {
                try {
                    const requestUrl = `${process.env.REACT_APP_GET_VALIDACAO_PRINT_IMAGENS}?&user_id=${dataUser.iDTokenAuthenticated}&token=${dataUser.saveToken}&lang=${dataUser.language}&id_proposta=${propostaInfo.idProposta}&tipo=print_proposta`;

                    const response = await axios.get(requestUrl);

                    console.log(response.data); // Verifica a resposta no console

                    if (response.data.sucesso) {
                        setIsSuccessPrintAnuncio(true); // Para os pedidos futuros
                        message.success(response.data.mensagem);
                        setPropostaInfo((prevData) => ({
                            ...prevData,
                            printAnuncioParaDownload: response.data.print_proposta,
                        }));
                    }
                } catch (error) {
                    console.error('Erro ao buscar dados:', error);
                }
            };

            // Configura o intervalo de 3 segundos
            const intervalId = setInterval(fetchData, 3000);

            // Configura o timeout para limpar o intervalo após 20 segundos
            const timeoutId = setTimeout(() => {
                clearInterval(intervalId);
            }, 50000);

            // Limpa tanto o intervalo quanto o timeout ao desmontar ou em caso de sucesso
            return () => {
                clearInterval(intervalId);
                clearTimeout(timeoutId);
            };

        } else {
            setIsSuccessPrintAnuncio(true); // Para os pedidos futuros
        }
    }, [isSuccessPrintAnuncio, propostaInfo.idProposta]);




    return (
        <div className='w-full flex flex-col justify-center items-center gap-4'>
            {/* Exibe o loading enquanto os dados estão sendo carregados */}
            {loading ? (
                <Loading />
            ) : (
                <>
                    {/* Exibe os dados do carro se existirem */}
                    {dataCarros ? (
                        <div id="content-to-capture" className="w-full text-white grid gap-4">
                            <div>
                                <div className='card grid gap-4'>
                                    <AssociarPartilharProposta
                                        propostaInfo={propostaInfo}
                                        dataUser={dataUser}
                                        dataCarros={dataCarros}
                                        setHubspotInfo={setHubspotInfo}
                                        hubspotInfo={hubspotInfo}
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        setPropostaInfo={setPropostaInfo}
                                        isvIucData={isvIucData}
                                        setIsvIucData={setIsvIucData}
                                        formatarNumero={formatarNumero}
                                    />
                                </div>
                            </div>


                            <div className='flex flex-col sm:flex-row gap-4 w-full h-full'>
                                <div className='w-full sm:w-3/5 flex flex-col gap-4'>
                                    {/* Tabela com os dados do carro */}
                                    <TabelaDadosCarroProposta
                                        dataCarros={dataCarros}
                                        isvIucData={isvIucData}
                                        urlCarroLink={dataCarros.urlCarroLink}
                                        dataUser={dataUser}
                                        propostaInfo={propostaInfo}
                                        setPropostaInfo={setPropostaInfo}
                                        valoresTotais={valoresTotais}
                                    />
                                    {/* {dataUser.iDTokenAuthenticated == 1 && (
                                        <SimuladorDeCreditoDentroDaProposta
                                            dataCarros={dataCarros}
                                            isvIucData={isvIucData}
                                            urlCarroLink={dataCarros.urlCarroLink}
                                            dataUser={dataUser}
                                            propostaInfo={propostaInfo}
                                            setPropostaInfo={setPropostaInfo}
                                            valoresTotais={valoresTotais}
                                        />
                                    )} */}
                                </div>

                                <div className="w-full sm:w-2/5 flex flex-col gap-4">

                                    <div className='card grid gap-4'>

                                        <div className={`flex items-center gap-4`}>
                                            <span className='text-lg font-semibold'>Informações da Proposta e Anúncio</span>
                                        </div>

                                        <hr />


                                        <div className='card-white grid gap-2'>

                                            <div className='flex justify-between items-center'>
                                                <p className="text-black text-base">
                                                    Data de Criação
                                                </p>
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <div className="font-semibold px-3 w-auto text-black">
                                                            <span className="ml-2">{propostaInfo.outros.data_criacao}</span>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >

                                            <div className='flex justify-between items-center'>
                                                <p className="text-black text-base">
                                                    Data de Expiração
                                                </p>
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        <div className="font-semibold px-3 w-auto text-black">
                                                            <span className="ml-2">{propostaInfo.outros.data_expira}</span>
                                                        </div>
                                                    </div>
                                                </div >
                                            </div >

                                            {propostaInfo.estadoDaProposta !== "nao_associada" && (
                                                <>
                                                    <FasesDaProposta
                                                        propostaInfo={propostaInfo}
                                                    />
                                                </>
                                            )}

                                            <div className='flex justify-between items-center'>
                                                <p className="text-black text-base">
                                                    Estado do Anúncio
                                                </p>
                                                <div className="flex justify-between gap-4">
                                                    <div className="flex items-center gap-4">
                                                        {propostaInfo.propostaDisponivelOnline === 0 && (
                                                            <div className="rounded-full bg-[#156813] font-semibold px-3 w-auto inline-flex items-center text-white">
                                                                {/* <RiRadioButtonLine /> */}
                                                                <span>{t('tables.proposta_dispon_online')}</span>
                                                            </div>
                                                        )}
                                                        {propostaInfo.propostaDisponivelOnline === 1 && (
                                                            <div className="rounded-full bg-[#b63911] font-semibold px-3 w-auto inline-flex items-center text-white">
                                                                {/* <RiRadioButtonLine /> */}
                                                                <span>{t('tables.proposta_indispon_online')}</span>
                                                            </div>
                                                        )}
                                                        {propostaInfo.propostaExpirou === 1 && (
                                                            <div className="rounded-full bg-[#b63911] font-semibold px-3 w-auto inline-flex items-center text-white">
                                                                {/* <RiRadioButtonLine /> */}
                                                                <span>{t('tables.proposta_indispon_intern')}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div >
                                            </div >



                                            <hr />

                                            <DownloadDoPrintAnuncio
                                                isSuccessPrintAnuncio={isSuccessPrintAnuncio}
                                                dataUser={dataUser}
                                                propostaInfo={propostaInfo}
                                                setPropostaInfo={setPropostaInfo}
                                            />
                                            <DownloadDasImagens
                                                dataUser={dataUser}
                                                propostaInfo={propostaInfo}
                                                setPropostaInfo={setPropostaInfo}
                                            />
                                        </div>

                                    </div>


                                    {hubspotInfo.idDoDeal !== "" && (
                                        <InfoPerfilClienteHubspotProposta
                                            dataUser={dataUser}
                                            dataCarros={dataCarros}
                                            setHubspotInfo={setHubspotInfo}
                                            hubspotInfo={hubspotInfo}
                                            setPropostaInfo={setPropostaInfo}
                                            propostaInfo={propostaInfo}
                                        />
                                    )}

                                    <FormCamposCalculoProposta
                                        isvIucData={isvIucData}
                                    />

                                    <PrecoFinalProposta
                                        valoresTotais={valoresTotais}
                                        setValoresTotais={setValoresTotais}
                                        dataUser={dataUser}
                                    />

                                    {propostaInfo.outros.estimativa_entrega && (
                                        <DuracaoDeProcesso
                                            valoresTotais={valoresTotais}
                                            propostaInfo={propostaInfo}
                                        />
                                    )}

                                    <EstimativaDeComissao
                                        valoresTotais={valoresTotais}
                                        formatarNumero={formatarNumero}
                                    />

                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
}

import React from 'react';

const FormCamposCalculoProposta = ({ isvIucData }) => {

    console.log(isvIucData)

    return (
        <div className='card grid gap-4'>
            <div className='flex justify-between items-center gap-4'>
                <div className='grid gap-1'>
                    <p className='text-lg font-semibold'>Cálculo do ISV</p>
                </div>
            </div>
            <hr />

            <div className="grid gap-4">
                <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 text-white'>

                    {/* Exibe Cilindrada */}
                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Cilindrada</p>
                            <p className='text-base'>{isvIucData.cilindrada || 'Sem informação'}</p>
                        </div>
                    )}

                    {/* Exibe Estado */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Novo / Usado</p>
                        <p className='text-base'>{isvIucData.novoUsado == "1" ? "Novo" : "Usado"}</p>
                    </div>

                    {/* Exibe Tipo de Combustível */}
                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Tipo de Combustível</p>
                        <p className='text-base'>{isvIucData.tipoCombustivel || 'Sem informação'}</p>
                    </div>

                    {/* Exibe Emissões CO2 */}
                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Emissões Gases C02</p>
                            <p className='text-base'>{isvIucData.emissoesCO2 || 'Sem informação'}</p>
                        </div>
                    )}

                    {/* Exibe Híbrido Plug-In */}
                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Híbrido Plug-In</p>
                            <p className='text-base'>{isvIucData.hibridoPlugIn ? 'Sim' : 'Não'}</p>
                        </div>
                    )}

                    {/* Exibe WLTP */}
                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Norma</p>
                            <p className='text-base'>{isvIucData.wltp == "0" ? 'NEDC' : 'WLTP'}</p>
                        </div>
                    )}

                    {/* Exibe Data 1º Registo */}
                    {isvIucData.novoUsado !== 1 && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Data 1º Registo</p>
                            <p className='text-base'>{isvIucData.dataPrimeiroRegisto || 'Sem informação'}</p>
                        </div>
                    )}

                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Tipo de Veículo</p>
                        <p className='text-base'>{isvIucData.tipoDeVeiculoParaOutros || 'Sem informação'}</p>
                    </div>

                    <div className='flex flex-col items-stretch gap-2'>
                        <p className='text-neutral-500 font-semibold text-sm'>Tipo de Isenção</p>
                        <p className='text-base'>{isvIucData.tipoDeVeiculoParaInsencoes || 'Sem informação'}</p>
                    </div>

                    {isvIucData.tipoCombustivel !== "Eletrico" && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Particulas</p>
                            <p className='text-base'>{isvIucData.particulas == 0 ? "<= 0,001" : ">= 0,001"}</p>
                        </div>
                    )}


                    {isvIucData.hibridoPlugIn == 1 && (
                        <div className='flex flex-col items-stretch gap-2'>
                            <p className='text-neutral-500 font-semibold text-sm'>Possui pelo menos 25km de autonomia</p>
                            <p className='text-base'>{isvIucData.hibridoMais25kmAutonomia == "1" ? 'Sim' : 'Não'}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormCamposCalculoProposta;

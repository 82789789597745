import { message, Modal, Button, Tooltip, Select } from 'antd';
import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

export default function MotivoRejeicaoDocumentacaoPerfilDeal({ data, dataUser, documentacao, setDocumentacao, confirmarDocumentos }) {


    const { t } = useTranslation();

    // Função para enviar o motivo da rejeição
    const handleEnviarMotivo = () => {
        if (documentacao.descricaoDaRejeicao.trim() === "" || documentacao.tipoDocumento.length === 0) {
            message.warning(t('hubspot.preencher_motivo_selecao_documento'));
        } else {
            confirmarDocumentos(documentacao.statusSelecionado, documentacao.descricaoDaRejeicao); // Envia o status e o motivo
            setDocumentacao((prevData) => ({
                ...prevData,
                isMotivoDaRejeicao: false,
            }));
        }
    };

    // Função para lidar com a melhoria do motivo com IA (Placeholder)
    const handleMotivoComAI = () => {
        if (!documentacao.descricaoDaRejeicao) {
            message.error(t('hubspot.motivo_vazio_ia'));
            return;
        }

        const dataMotivoComAI = {
            user_id: dataUser.iDTokenAuthenticated,
            token: dataUser.saveToken,
            tipo_documento: documentacao.tipoDocumento,
            motivo: documentacao.descricaoDaRejeicao,
        };

        axios.post(`${process.env.REACT_APP_URL_CHATGPT}`, dataMotivoComAI)
            .then((response) => {
                if (response.data.sucesso) {
                    setDocumentacao((prevData) => ({
                        ...prevData,
                        descricaoDaRejeicao: response.data.mensagem,
                    }));
                } else {
                    message.error(response.data.mensagem);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            {/* Modal para o motivo da rejeição */}
            <Modal
                title={t('hubspot.motivo_rejeicao')}
                open={documentacao.isMotivoDaRejeicao}
                onCancel={() =>
                    setDocumentacao((prevData) => ({
                        ...prevData,
                        isMotivoDaRejeicao: false,
                    }))
                }
                footer={[
                    <div className='flex gap-2 justify-between' key="footer">
                        <div className='flex gap-2 items-center'>
                            <Button className='m-0 bg-blue-400 text-white font-bold shadow-none' key="ai" onClick={handleMotivoComAI}>
                                {t('hubspot.melhorar_com_ai')}
                            </Button>
                            <Tooltip title={t('hubspot.tooltip_ai')}>
                                <AiOutlineInfoCircle className='icon-question' />
                            </Tooltip>
                        </div>

                        <Button className='m-0 font-bold shadow-none' key="submit" type="primary" onClick={handleEnviarMotivo}>
                            {t('hubspot.enviar')}
                        </Button>
                    </div>,
                ]}
            >
                <div className='grid gap-2'>
                    <p className='font-semibold'>{t('hubspot.tipo_documento_invalido')}</p>
                    <Select
                        mode="multiple"
                        placeholder={t('hubspot.selecionar_tipo_documento')}
                        value={documentacao.tipoDocumento}
                        style={{ width: '100%', marginBottom: 16 }}
                        onChange={(value) => {
                            setDocumentacao((prevData) => ({
                                ...prevData,
                                tipoDocumento: value,
                            }));
                        }}
                    >
                        {data?.documentosData.tipos_documentos.map((campo) => (
                            <Option key={campo.slug} value={campo.slug}>
                                {campo.label}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='grid gap-2'>
                    <p className='font-semibold'>{t('hubspot.motivo_rejeicao')}</p>
                    <TextArea
                        className="w-full p-2 border"
                        rows={4}
                        value={documentacao.descricaoDaRejeicao}
                        onChange={(e) =>
                            setDocumentacao((prevData) => ({
                                ...prevData,
                                descricaoDaRejeicao: e.target.value,
                            }))
                        }
                        placeholder={t('hubspot.descreve_motivo_rejeicao')}
                    />
                </div>
            </Modal>
        </>
    );
}

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next'; // Importa o hook de tradução
import logo from '../../assets/logo-branco 2.svg';

import { useMediaQuery } from 'react-responsive';
import LoginForm from './LoginForm';

export default function Login() {
    const { t } = useTranslation(); // Hook para pegar as traduções

    // Responsividade
    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });

    useEffect(() => {
        document.body.style.backgroundColor = 'hsl(0, 0%, 0%)';
        document.body.style.backgroundImage = 'radial-gradient(circle farthest-side at left center, hsl(0deg 0% 46% / 41%) 0%, hsl(0, 0%, 0%) 100%)';
        document.body.style.backgroundSize = 'cover';

        return () => {
            document.body.style.backgroundColor = '#000000';
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
        };
    }, []);

    return (
        <div className={` ${isMobile ? 'flex flex-col justify-center gap-4' : 'grid grid-cols-10'}`}>
            <div className={`flex flex-col items-center ${isMobile ? '' : 'col-span-6 items-baseline justify-between'}`}>
                <a href='https://importrust.com/' target='_blank' rel="noopener noreferrer">
                    <img
                        src={logo}
                        alt="Logo"
                        className="w-40 max-w-40 h-10 object-contain cursor-pointer"
                    />
                </a>
                {!isMobile && (
                    <>
                        <div>
                            <h2 className="text-2xl md:text-4xl font-bold w-3/5">
                                {t('login.import_experts')} {/* Traduz a chave específica */}
                            </h2>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="flex flex-row gap-3">
                                <div className='card'>
                                    <h3 className="text-xl md:text-2xl font-semibold">+3000</h3>
                                    <span className="text-xs">{t('login.satisfied_clients')}</span> {/* Traduz a chave específica */}
                                </div>
                                <div className='card'>
                                    <h3 className="text-xl md:text-2xl font-semibold">5000€</h3>
                                    <span className="text-xs">{t('login.average_savings')}</span> {/* Traduz a chave específica */}
                                </div>
                                <div className='card'>
                                    <h3 className="text-xl md:text-2xl font-semibold">10M€</h3>
                                    <span className="text-xs">{t('login.total_savings')}</span> {/* Traduz a chave específica */}
                                </div>
                            </div>

                            <div className='flex gap-2 items-center'>
                                <div className="flex items-center">
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                    <span className="text-yellow-500">★</span>
                                </div>
                                <span className="text-xs">{t('login.average_client_rating')}</span> {/* Traduz a chave específica */}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="md:col-span-4 content-center">
                <div className="card-white text-black flex flex-col justify-between py-10 md:p-4">
                    <div>
                        <div className="flex flex-col items-center gap-2">
                            <span>{t('login.login')}</span> {/* Traduz a chave específica */}
                            <span className='font-bold text-xl'>{t('login.bemVindo')}</span>
                        </div>
                        <div className="pt-10 md:pt-10 pb-10 md:pb-10 px-5">
                            <LoginForm />
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-2">
                        <a href="https://importrust.com" target="_blank" rel="noopener noreferrer">
                            importrust.com
                        </a><span className='text-gray-400 text-[10px]'>version {process.env.REACT_APP_VERSION}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState, useEffect, useRef } from "react";
// import FormCalculadoraCredito from "./FormCalculadoraCreditoDentroDaProposta";
// import ResultadoFinanceiro from "./Resultados/ResultadoFinanceiro";
// import ResultadoTotal from "./Resultados/ResultadoTotal";
// import ResultadoTaxas from "./Resultados/ResultadoTaxas";
import FormCalculadoraCreditoDentroDaProposta from "./FormCalculadoraCreditoDentroDaProposta";
import { MdOutlineExpandMore, MdOutlineKeyboardArrowUp } from "react-icons/md";

export default function SimuladorDeCreditoDentroDaProposta({
    dataCarros,
    isvIucData,
    urlCarroLink,
    dataUser,
    propostaInfo,
    valoresTotais,
}) {

   // const divRef = useRef(null);  // Referência para a div que será capturada

    const [moreSimulador, setMoreSimulador] = useState(false);

    // Função para alternar o estado
    const toggleSimulador = () => {
        setMoreSimulador((prevState) => !prevState);
    };


    const [valores, setValores] = useState({
        taxaDeJuroCredito: 6.50,
        entradaCredito: 0,
        valorFinanciadoCredito: 0,
        prestacoesMesesCredito: 24,
        valorFinalCredito: 0,
        valorChaveNaMaoParaCredito: 0,
        valorChaveNaMao: valoresTotais.valorPrecoChaveNaMaoParticular,
        valorEntrada: 0,
        mticValor: 0,
        jurosValor: 0,
        comissaoDeCapital: 400,
        encargosdespesasValor: 2400,
        impostoDoSeloValor: 0,
        valorDoCarro: 0,
        valorDasDespesas: 0,
    });

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(valor);
    };

    useEffect(() => {

        const calcularPrestacao = () => {

            const { valorEntrada, prestacoesMesesCredito, valorChaveNaMaoParaCredito } = valores;

            if (!isNaN(valorEntrada) && !isNaN(valorChaveNaMaoParaCredito) && !isNaN(prestacoesMesesCredito)) {
                const taxaJurosMensal = valores.taxaDeJuroCredito / (12 * 100);
                const prestacao = (valorChaveNaMaoParaCredito * taxaJurosMensal) / (1 - Math.pow(1 + taxaJurosMensal, -prestacoesMesesCredito));
                setValores((prev) => ({ ...prev, valorFinalCredito: prestacao.toFixed(2) }));
            }
        };

        const calcularJuros = () => {
            const juros = (parseFloat(valores.valorFinalCredito) * parseFloat(valores.prestacoesMesesCredito)) - parseFloat(valores.valorChaveNaMaoParaCredito);
            const impostoDoSelo = juros * 0.004;
            setValores((prev) => ({
                ...prev,
                jurosValor: juros.toFixed(2),
                impostoDoSeloValor: impostoDoSelo.toFixed(2),
            }));
        };

        const calcularMtic = () => {
            const mtic = (parseFloat(valores.valorFinalCredito) * parseFloat(valores.prestacoesMesesCredito)) + parseFloat(valores.encargosdespesasValor);
            setValores((prev) => ({ ...prev, mticValor: mtic.toFixed(2) }));
        };

        calcularPrestacao();
        calcularMtic();
        calcularJuros();
    }, [valores.valorEntrada, valores.taxaDeJuroCredito, valores.prestacoesMesesCredito, valores.encargosdespesasValor, valores.valorFinalCredito]);

    useEffect(() => {
        if (valores.valorChaveNaMao > 0) {
            const novoValorEntrada = 0.2 * valores.valorChaveNaMao;
            setValores((prev) => ({
                ...prev,
                valorEntrada: novoValorEntrada,
                valorChaveNaMaoParaCredito: valores.valorChaveNaMao - novoValorEntrada,
            }));
        }
    }, [valores.valorChaveNaMao]);

    useEffect(() => {
        const valorCalculado = parseFloat(valores.valorEntrada) + parseFloat(valores.mticValor) + parseFloat(valores.comissaoDeCapital);
        setValores((prev) => ({
            ...prev,
            valorDoCarro: valorCalculado,
        }));
    }, [valores.valorEntrada, valores.mticValor, valores.comissaoDeCapital]);

    useEffect(() => {
        const valorCalculado = parseFloat(valores.encargosdespesasValor) + parseFloat(valores.comissaoDeCapital);
        setValores((prev) => ({
            ...prev,
            valorDasDespesas: valorCalculado,
        }));
    }, [valores.encargosdespesasValor, valores.comissaoDeCapital]);

    return (
        <div className='card grid gap-4'>
            <div className='grid gap-4'>


                <div onClick={toggleSimulador} className="flex justify-between items-center gap-4 cursor-pointer">
                    <div className="grid gap-1">
                        <p className="text-lg font-semibold">Simulador de Crédito</p>
                    </div>

                    {moreSimulador ? (
                        <MdOutlineKeyboardArrowUp size={25} />
                    ) : (
                        <MdOutlineExpandMore size={25} />
                    )}

                </div>


                {moreSimulador && (
                    <>
                        <hr />
                        <div className="w-full">
                            <FormCalculadoraCreditoDentroDaProposta
                                valores={valores}
                                setValores={setValores}
                                formatarNumero={formatarNumero}
                            />
                        </div>

                        <div>
                            <div className="bg-tipos grid grid-cols-1 gap-6 mt-5 md:mt-0">
                                <hr />
                                <div className="p-4 bg-white text-black rounded flex items-center justify-between">
                                    <h2 className="font-bold text-base">Total da simulação</h2>
                                    <div className="flex gap-4 items-center">
                                        <div className="w-fit flex rounded bg-[#3C3C40] p-4 items-center text-white">
                                            <p><span className="text-base sm:text-base font-bold">{valores.prestacoesMesesCredito}</span>x</p>
                                        </div>
                                        <p><span className="text-lg font-bold">{formatarNumero(valores.valorFinalCredito)}</span> €/mês</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </>
                )}
            </div>
        </div >
    );
}

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Loading from '../Elements/Loading';

// Custom tooltip component to add Euro symbol
const CustomTooltip = ({ payload }) => {
    if (payload && payload.length) {
        return (
            <div className="custom-tooltip text-sm text-center bg-gray-700 text-white p-2 rounded">
                {payload[0].payload.name}<br /> <strong>{payload[0].value}</strong>
            </div>
        );
    }
    return null;
};

export default function ChartPropostas({ data }) {
    console.log(data);

    const isLoading = !data || data.length === 0;

    // Transformar os dados no formato correto
    const dataTable = (data || []).map(item => ({
        name: item.mes,
        value: item.propostas
    }));

    return (
        <div>
            {isLoading ? (
                <Loading />
            ) : (
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={dataTable}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tick={{ fill: 'white' }} />
                            <YAxis tick={{ fill: 'white' }} />
                            <Tooltip content={<CustomTooltip />} />
                            <Line type="monotone" dataKey="value" stroke="#75AD13" strokeWidth={4} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            )}
        </div>
    );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { Slider, InputNumber, Form, Tooltip } from "antd";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function FormCalculadoraCredito({ valores, setValores, formatarNumero }) {

    const { t } = useTranslation();

    // Função para lidar com mudanças de valor de entrada
    const handleValorEntrada = (valor) => {
        setValores((prev) => ({
            ...prev,
            valorEntrada: valor,
            valorChaveNaMaoParaCredito: valores.valorChaveNaMao - valor
        }));
    };

    const handlePrestacoesMesesCredito = (valor) => {
        setValores((prev) => ({ ...prev, prestacoesMesesCredito: valor }));
    };

    const handleTaxaDeJuroCredito = (valor) => {
        setValores((prev) => ({ ...prev, taxaDeJuroCredito: valor }));
    };

    const handleValorChaveNaMao = (valor) => {
        setValores((prev) => ({ ...prev, valorChaveNaMao: valor }));
    };

    const handleEncargosEDespesas = (valor) => {
        setValores((prev) => ({ ...prev, encargosdespesasValor: valor }));
    };

    const handleComissaoDeCapital = (valor) => {
        setValores((prev) => ({ ...prev, comissaoDeCapital: valor }));
    };


    const converterMesesEmAnosEMeses = (meses) => {
        const anos = Math.floor(meses / 12);
        const mesesRestantes = meses % 12;
        return `${anos} ano${anos !== 1 ? 's' : ''} e ${mesesRestantes} mes${mesesRestantes !== 1 ? 'es' : ''}`;
    };


    return (
        <Form layout="vertical" className="text-white">
            <div className='grid gap-10 credito-main-block'>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                    <div>
                        <p className="flex items-center gap-2">
                            <span>Valor Chave na Mão</span>
                            <Tooltip title="O Valor Chave na Mão inclui valor da Viatura, valor do ISV e o valor da Taxa de Serviço.">
                                <AiOutlineInfoCircle size={15} />
                            </Tooltip>
                        </p>
                        <h2 className="text-base font-extrabold">
                            {valores.valorChaveNaMao ? `${formatarNumero(valores.valorChaveNaMao)}` : `0€`}
                        </h2>
                        <Form.Item>
                            <div className='flex gap-2 items-center'>
                                <Slider
                                    min={0}
                                    max={500000}
                                    step={0.1}
                                    value={valores.valorChaveNaMao}
                                    onChange={handleValorChaveNaMao}
                                    style={{ width: '100%' }}
                                />
                                <InputNumber
                                    min={0}
                                    max={500000}
                                    step={0.1}
                                    value={valores.valorChaveNaMao}
                                    onChange={handleValorChaveNaMao}
                                    placeholder='Preenche com o valor chave na mão'
                                    className="w-36"
                                />
                            </div>
                        </Form.Item>
                    </div>

                    <div>
                        <p>Em quantos meses?</p>
                        <div className='flex items-center gap-2'>
                            <h2 className="text-base font-extrabold">
                                {valores.prestacoesMesesCredito ? `${valores.prestacoesMesesCredito} meses` : `0 meses`}
                            </h2>
                            <div>|</div>
                            <p>{converterMesesEmAnosEMeses(valores.prestacoesMesesCredito)}</p>
                        </div>
                        <Form.Item>
                            <div className='flex gap-2 items-center'>
                                <Slider
                                    min={24}
                                    max={120}
                                    step={1}
                                    value={valores.prestacoesMesesCredito}
                                    onChange={handlePrestacoesMesesCredito}
                                    style={{ width: '100%' }}
                                />
                                <InputNumber
                                    min={24}
                                    max={120}
                                    step={1}
                                    value={valores.prestacoesMesesCredito}
                                    onChange={handlePrestacoesMesesCredito}
                                    placeholder='Preenche com o número de meses'
                                    className="w-36"
                                />
                            </div>
                        </Form.Item>
                    </div>

                    <div>
                        <p className="flex items-center gap-2">
                            <span>Valor de Entrada</span>
                            <Tooltip title="O valor de entrada não pode ser inferior a 20% do valor financiado.">
                                <AiOutlineInfoCircle size={15} />
                            </Tooltip>
                        </p>
                        <h2 className="text-base font-extrabold">
                            {valores.valorEntrada ? `${formatarNumero(valores.valorEntrada)}` : `0€`}
                        </h2>
                        <Form.Item>
                            <div className='flex gap-2 items-center'>
                                <Slider
                                    min={0.2 * valores.valorChaveNaMao} // Mínimo é 20% do valor chave na mão
                                    max={valores.valorChaveNaMao}
                                    step={0.1}
                                    value={valores.valorEntrada}
                                    onChange={handleValorEntrada}
                                    style={{ width: '100%' }}
                                />
                                <InputNumber
                                    min={0.2 * valores.valorChaveNaMao} // Mínimo é 20% do valor chave na mão
                                    max={valores.valorChaveNaMao}
                                    step={0.1}
                                    value={valores.valorEntrada}
                                    onChange={handleValorEntrada}
                                    placeholder='Preenche com o valor de entrada'
                                    className="w-36"
                                />
                            </div>
                        </Form.Item>
                    </div>

                    <div>
                        <p>Taxa de Juro</p>
                        <h2 className="text-base font-extrabold">{valores.taxaDeJuroCredito}%</h2>
                        <Form.Item>
                            <div className='flex gap-2 items-center'>
                                <Slider
                                    min={6.5}
                                    max={100}
                                    step={0.1}
                                    value={valores.taxaDeJuroCredito}
                                    onChange={handleTaxaDeJuroCredito}
                                    style={{ width: '100%' }}
                                />
                                <InputNumber
                                    min={0}
                                    max={100}
                                    step={0.1}
                                    value={valores.taxaDeJuroCredito}
                                    onChange={handleTaxaDeJuroCredito}
                                    className="w-36"
                                />
                            </div>
                        </Form.Item>
                    </div>
                </div>

                {valores.valorChaveNaMaoParaCredito && valores.prestacoesMesesCredito ? (
                    <>
                        <div className='p-4 bg-white text-black rounded flex items-center justify-between'>
                            <p className="flex items-center gap-2">
                                <span className='font-bold text-base'>Valor a Financiar</span>
                                <Tooltip title="O Valor a Financiar correponde ao valor total do negócio (valor da viatura, isv e taxa de serviço) menos o valor de entrada.">
                                    <AiOutlineInfoCircle size={15} />
                                </Tooltip>
                            </p>
                            <h2 className="text-lg font-extrabold">
                                {valores.valorChaveNaMaoParaCredito ? `${formatarNumero(valores.valorChaveNaMaoParaCredito)}` : `0€`}
                            </h2>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <p className="flex items-center gap-2">
                                    <span>Encargos e Despesas</span>
                                    <Tooltip title="Encargos e Despesas - colocar texto">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <div className='flex items-center gap-2'>
                                    <h2 className="text-base font-extrabold">
                                        {valores.encargosdespesasValor ? `${formatarNumero(valores.encargosdespesasValor)}` : `0 €`}
                                    </h2>
                                </div>
                                <Form.Item>
                                    <div className='flex gap-2 items-center'>
                                        <Slider
                                            min={1}
                                            max={5000}
                                            step={1}
                                            value={valores.encargosdespesasValor}
                                            onChange={handleEncargosEDespesas}
                                            style={{ width: '100%' }}
                                        />
                                        <InputNumber
                                            min={1}
                                            max={5000}
                                            step={1}
                                            value={valores.encargosdespesasValor}
                                            onChange={handleEncargosEDespesas}
                                            placeholder='Preenche com o valor dos encargos'
                                            className="w-36"
                                        />
                                    </div>
                                </Form.Item>
                            </div>

                            <div>
                                <p>Comissão de Disponibilização de Capital</p>
                                <div className='flex items-center gap-2'>
                                    <h2 className="text-base font-extrabold">
                                        {valores.comissaoDeCapital ? `${formatarNumero(valores.comissaoDeCapital)}` : `0 €`}
                                    </h2>
                                </div>
                                <Form.Item>
                                    <div className='flex gap-2 items-center'>
                                        <Slider
                                            min={400}
                                            max={2000}
                                            step={1}
                                            value={valores.comissaoDeCapital}
                                            onChange={handleComissaoDeCapital}
                                            style={{ width: '100%' }}
                                        />
                                        <InputNumber
                                            placeholder='Preenche com o valor da comissão'
                                            min={400}
                                            max={2000}
                                            step={1}
                                            value={valores.comissaoDeCapital}
                                            onChange={handleComissaoDeCapital}
                                            className="w-36"
                                        />
                                    </div>
                                </Form.Item>
                            </div>

                        </div>


                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm">MTIC</span>
                                    <Tooltip title="Corresponde ao Montante Total Imputado ao Consumidor (MTIC), que indica o valor total que vai pagar pelo crédito, incluindo o montante do crédito financiado, juros, comissões, impostos e encargos.">
                                        <AiOutlineInfoCircle size={15} />
                                    </Tooltip>
                                </p>
                                <p><span className="text-base sm:text-base  font-bold">{valores.mticValor ? `${formatarNumero(valores.mticValor)}` : `0 €`}</span></p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm text-center">Montante financiado</span>
                                </p>
                                <p><span className="text-base sm:text-base  font-bold">{valores.valorChaveNaMaoParaCredito ? `${formatarNumero(valores.valorChaveNaMaoParaCredito)}` : `0 €`}</span></p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm text-center">Juros</span>
                                </p>
                                <p className="flex items-center gap-2 justify-center"><span className="text-base sm:text-base  font-bold">{valores.jurosValor ? `${formatarNumero(valores.jurosValor)}` : `0 €`}</span></p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 justify-items-center bg-[#fff] rounded text-black p-2">
                                <p className="flex items-center gap-2 justify-center">
                                    <span className="text-sm text-center">Encargos e Despesas</span>
                                </p>
                                <p className='flex items-center gap-2 justify-center'><span className="text-base sm:text-base  font-bold">{valores.encargosdespesasValor ? `${formatarNumero(valores.valorDasDespesas)}` : `0 €`}</span></p>
                            </div>

                        </div>

                    </>
                ) : null}
            </div>
        </Form>
    );
}
import axios from 'axios';
import { FaCheckCircle, FaClock, FaRegFile } from 'react-icons/fa';
import { BsFiletypePdf, BsFiletypeJpg, BsFiletypeSvg, BsFiletypeTxt, BsFiletypePng } from 'react-icons/bs';
import { IoMdCloseCircle } from 'react-icons/io';
import { HiOutlineDownload, HiDotsVertical } from 'react-icons/hi';
import { Button, Popover, message } from 'antd';

export default function VerDocumentacaoPerfilDeal({ data, setData, dataUser }) {

    // Função para download de arquivos
    const handleDownloadFicheiros = async (fileNames) => {
        if (typeof fileNames === 'string') {
            fileNames = [fileNames];
        }

        try {
            for (const fileName of fileNames) {
                const response = await axios.get(`${process.env.REACT_APP_DOWNLOAD_DOS_FICHEIROS}`, {
                    params: {
                        token: dataUser.saveToken,
                        user_id: dataUser.iDTokenAuthenticated,
                        nome_ficheiro: fileName,
                        id_deal: data.idDeal,
                        tipo: 'documentacao_deal'
                    },
                    responseType: 'blob'
                });

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                message.success('Download do ficheiro com sucesso!');
            }
        } catch (error) {
            console.error('Erro ao fazer download do arquivo:', error);
            message.error('Erro ao fazer download de um ou mais arquivos. Tente novamente.');
        }
    };

    // Ícones de tipo de ficheiro
    const iconTypeFicheiro = (fileType) => {
        switch (fileType) {
            case 'pdf':
                return <BsFiletypePdf style={{ fontSize: '35px', color: '#E62335' }} />;
            case 'jpg':
            case 'jpeg':
                return <BsFiletypeJpg style={{ fontSize: '35px', color: '#EAB308' }} />;
            case 'svg':
                return <BsFiletypeSvg style={{ fontSize: '35px', color: '#1F77B4' }} />;
            case 'png':
                return <BsFiletypePng style={{ fontSize: '35px', color: '#00CC69' }} />;
            case 'txt':
                return <BsFiletypeTxt style={{ fontSize: '35px', color: '#00BFFF' }} />;
            default:
                return <FaRegFile style={{ fontSize: '35px', color: '#C0C0C0' }} />;
        }
    };

    // Componente para cada documento
    const DocumentoItem = ({ titulo, fileName, onDownload, fileType, textValue }) => (
        <div className="bg-black text-white border-card py-4 px-3.5 flex items-center justify-between gap-2 transition-all">
            <div className="flex items-center gap-2 text-white">
                {fileType ? iconTypeFicheiro(fileType) : <BsFiletypeTxt style={{ fontSize: '35px', color: '#C0C0C0' }} />}
                <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold">{titulo}</p>
                </div>
            </div>
            {Array.isArray(fileName) && fileName.length > 0 ? (
                <Popover
                    placement="bottomRight"
                    content={
                        <Button
                            className="flex items-center shadow-none font-bold text-black bg-white"
                            icon={<HiOutlineDownload />}
                            onClick={onDownload}
                        >
                            Download
                        </Button>
                    }
                >
                    <HiDotsVertical className="cursor-pointer" />
                </Popover>
            ) : (
                <p className="text-sm text-white">{textValue || "Nenhum valor disponível"}</p>
            )}
        </div>
    );

    // Renderizar campos de documentos
    const renderField = (documentos) => {
        return documentos.map((doc, index) => {
            const file = doc.value?.[0]; // Verifica se "value" é um array e pega o primeiro item
            const fileName = Array.isArray(doc.value) ? doc.value.map(item => item.value) : null;
            const fileType = file?.extensao;
            const textValue = !Array.isArray(doc.value) ? doc.value : null; // Se não for array, será tratado como texto

            return (
                <DocumentoItem
                    key={`${doc.label}-${index}`}
                    titulo={doc.label}
                    fileName={fileName}
                    onDownload={fileName ? () => handleDownloadFicheiros(fileName) : null}
                    fileType={fileType}
                    textValue={textValue}
                />
            );
        });
    };

    return (
        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 my-4 gap-4 lg:gap-6">
            {renderField(data.documentosData.ultimos_documentos_enviados)}
        </div>
    );
}
